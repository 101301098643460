/* Container styles */
.container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Header styles */
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Grid styles */
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
  
  /* Empty text styles */
  .emptyText {
    grid-column: 1 / -1;
    text-align: center;
    color: #888;
  }
  
  /* Shop Now container */
  .shopNowContainer {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  /* Shop Now button */
  .shopNowButton {
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Media queries */
  @media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  
  @media (max-width: 480px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  




@media (max-width: 768px) {
    .grid {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  
  @media (max-width: 480px) {
    .grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  